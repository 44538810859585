@font-face {
  font-family: 'BrandonText';
  src: url('./brandontext-regular-webfont.eot');
  src: url('./brandontext-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./brandontext-regular-webfont.woff') format('woff'),
    url('./brandontext-regular-webfont.ttf') format('truetype'),
    url('./brandontext-regular-webfont.svg#BrandonText') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonText';
  src: url('./brandontext-bold-webfont.eot');
  src: url('./brandontext-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./brandontext-bold-webfont.woff') format('woff'),
    url('./brandontext-bold-webfont.ttf') format('truetype'),
    url('./brandontext-bold-webfont.svg#BrandonText') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonText';
  src: url('./brandontext-light-webfont.eot');
  src: url('./brandontext-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('./brandontext-light-webfont.woff') format('woff'),
    url('./brandontext-light-webfont.ttf') format('truetype'),
    url('./brandontext-light-webfont.svg#BrandonText') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonText';
  src: url('./brandontext-regularitalic-webfont.eot');
  src: url('./brandontext-regularitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./brandontext-regularitalic-webfont.woff') format('woff'),
    url('./brandontext-regularitalic-webfont.ttf') format('truetype'),
    url('./brandontext-regularitalic-webfont.svg#BrandonText') format('svg');
  font-weight: normal;
  font-style: italic;
}
